import { useState, useEffect } from "react";

export default function useNewsData(id) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchData = async (id) => {
    // https://lessentiel-nasdaq.europe-west1.firebasedatabase.app/news.json?orderBy=%22epoch%22&limitToFirst=15&print=pretty
    const baseUrl =
      "https://lessentiel-nasdaq.europe-west1.firebasedatabase.app";
    const url = id
      ? `${baseUrl}/news/${id}.json`
      : `${baseUrl}/news.json?orderBy=%22epoch%22&limitToLast=25`;

    // Get latest data from API
    try {
      setLoading(true);

      const response = await fetch(url);
      const apiData = await response.json();
      // console.log("fetchData data", apiData);

      if (id) {
        if (apiData?.headline === undefined) {
          throw new Error("No data.");
        }
        setData(apiData);
      } else {
        if (apiData === null) {
          throw new Error("No data.");
        }
        // data from Firebase is not sorted
        let newsArray = Object.values(apiData);
        const sortedNewsArray = newsArray.sort((a, b) => {
          return b.epoch - a.epoch;
        });
        setData(sortedNewsArray);
      }

      setLoading(false);
      setError("");
    } catch (error) {
      console.log("Error fetching data", error);

      setLoading(false);
      setError(error instanceof Error ? error.message : "Unknown error");
    }
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return { data, loading, error };
}
