import useNewsData from "../hooks/useNewsData";
import { formatDate } from "../date";
import { Link } from "react-router-dom";
import css from "./list.module.scss";

const News = () => {
  const { data, loading, error } = useNewsData();
  console.log(data);
  return (
    <div className="App">
      <h1>Communiqués de presse des entreprises</h1>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <>
          {data.map((item) => (
            <div key={item.id} className={css.news_item}>
              <Link to={"/fr/news/" + item.id} className={css.headline}>
                {item.headline}
              </Link>
              {/* <div>{item.epoch}</div> */}
              <div className={css.data_source}>
                <span className={css.date}>
                  {formatDate(item.date, "DD.MM.YYYY, hh:mm")}
                </span>
                |<span className={css.source}>{item.company}</span>
              </div>
              <div className={css.summary}>{item.summary}</div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default News;
