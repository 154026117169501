import { Link, useParams } from "react-router-dom";
import useNewsData from "../hooks/useNewsData";
import css from "./news.module.scss";

const News = () => {
  const { id } = useParams();
  // const id = "8972974-en";

  const { data, loading, error } = useNewsData(id);

  return (
    <div className="App">
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {!loading && !error && (
        <>
          <Link to="/fr" className={css.backButton}>
            &lt; Retour
          </Link>
          <div className={css.news}>
            <h1 className={css.headline}>{data.headline}</h1>
            <h2 className={css.company}>{data.company}</h2>
            <div
              className={css.content}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default News;
