import dayjs from "dayjs";

export const formatDate = (input, format = "DD.MM.YYYY") => {
  if (!input) {
    return "";
  }

  try {
    // 20231106T130000+0100
    const [, Y, M, D, h, m, s, tzh, tzm] = input.match(
      /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})([+-]\d{2})(\d{2})$/
    );

    if (tzm === undefined) {
      return "";
    }

    const formattedDate = `${Y}-${M}-${D}T${h}:${m}:${s}${tzh}:${tzm}`;

    return dayjs(formattedDate).format(format);
  } catch (error) {
    console.log("Error formatting date", error);

    return "";
  }
};
