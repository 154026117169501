import { BrowserRouter, Routes, Route } from "react-router-dom";
import List from "./pages/List";
import News from "./pages/News";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/fr/">
          <Route index element={<List />} />
          <Route path="news/:id" element={<News />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
        <Route path="/de/">
          <Route index element={<List />} />
          <Route path="news/:id" element={<News />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
